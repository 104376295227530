import React from "react"
import { graphql } from "gatsby"
import _ from "lodash"
import Img from 'gatsby-image'
// ---------------------------------------------
import Gallery from "../components/gallery"
import Markdown from "../components/markdown"
import Revealer from "../components/revealer"
// ---------------------------------------------

const Field = (props) => {
	switch (props.type) {
		case 'Short':
			return <InputText {...props} />
		case 'Long':
			return <InputTextArea {...props} />
		case 'Select':
			return <InputSelect {...props} />
		default:
			return null
	}
}

const InputText = ({ 
	label, 
	name,
	placeholder,
	required
}) => {

	const id = name

	return (
		<div className="input-wrapper">
			<label htmlFor={id}>{required ? `${label}*` : label}</label>
			<input type="text" name={id} placeholder={placeholder || "…"} required={required} />
		</div>
	)
}

const InputTextArea = ({ 
	label, 
	name,
	placeholder,
	required
}) => {

	// const id = _.camelCase(label)
	const id = name

	return (
		<div className="input-wrapper">
			<label htmlFor={id}>{required ? `${label}*` : label}</label>
			<textarea name={id} placeholder={placeholder || "…"} rows={4} required={required} />
		</div>
	)
}

const InputSelect = ({
	label, 
	name,
	placeholder,
	required,
	options,
	...props 
}) => {

	return (
		<div className="input-wrapper">
			<label htmlFor={name}>{label}</label>
			<select name={name}>
				<option label>{placeholder || 'Select an option…'}</option>
				{options.map((value, index) => <option value={value} key={`${name}${index}`}>{value}</option>)}
			</select>
		</div>
	)
}

const SectionFormGroup = ({ 
	id,
	title, 
	description, 
	customFormFields 
}) => {

	return (
		<div className="section-form-group outerx4">
			{title && <h5 className="">{title}</h5>}
			{description && <Markdown className={`md max24`} field={description} />}
			<fieldset>
				{customFormFields && customFormFields.map((node, index) => <Field {...node} key={`${id}field${index}`} />)}
			</fieldset>
		</div>
	)
}

const SectionForm = ({ 
	formId, 
	customFieldGroups, 
	location, 
	successPage 
}) => {

	// const pathname = location ? location.pathname : ''

	return (
		<div className="section-form gutters">
			<form 
				name={formId} 
				method="post"
				action={`/${successPage.slug}`}
        data-netlify="true"
				data-netlify-honeypot="bot-field"
			>
				<input type="hidden" name="form-name" value={formId} />
				{customFieldGroups && customFieldGroups.map((node, index) => {
					const id = `${formId}-group-${index}`
					return (
						<SectionFormGroup {...node} id={id} key={id} />
					)
				})}

				<div className="outerx4">
					<button type="submit" className="btn">Submit</button>
				</div>
			</form>
		</div>
	)
}

const Sections = ({ sections, bypassRevealer, location }) => {

	return (
		<div>
			{sections && sections.map((node, i) => {
				// Content
				const { 
					backgroundColour, 
					copy, 
					order, 
					imageSize, 
					reference, 
					images, 
					secondaryImages, 
					customForm 
				} = node
				const bg = backgroundColour;
				const hasPreviousBg = sections[i-1] && sections[i-1].backgroundColour;
				const hasNextBg = sections[i+1] && sections[i+1].backgroundColour;
				const style = {
					backgroundColor: bg ? bg : 'transparent',
					paddingTop: bg ? '1rem' : hasPreviousBg ? '1rem' : '0.5rem',
					paddingBottom: (i+1 === sections.length) || bg ? '1rem' : hasNextBg ? '1rem' : '0.5rem'
				}

				// This was to determine max width on copy wrapper, but actually the feedback was it should be the same
				// const isCopyLong = copy ? copy.copy.length > 480 : false
				// NOTE: Funky logic in here, could be tidyed up.

				// CSS
				const gridClassName = `section-grid ${!order ? 'reverse' : ''} ${customForm ? 'has-form' : ''}`
				const gridImageSize = { width: imageSize ? imageSize[0] : '50%' }

				return imageSize ? (
					<section className="section gutters" key={`${reference}-${i}`} style={style}>
						<Revealer force={bypassRevealer}>
							{secondaryImages ? (
								<div className="section-grid rows">
									<div className="gutters section-image" style={gridImageSize}>
										{images.length > 1 ? (
											<Gallery images={images} />
										) : (
											<Img fluid={images[0].fluid} />
										)}
									</div>
									<div className="gutters section-image" style={gridImageSize}>
										{images.length > 1 ? (
											<Gallery images={secondaryImages} />
										) : (
											<Img fluid={secondaryImages[0].fluid} />
										)}
									</div>
								</div>
							) : (
								<div className={gridClassName}>
									<div className="gutters section-image" style={gridImageSize}>
										{images && images.length > 1 ? (
											<Gallery images={images} />
										) : images && images[0] ? (
											<Img fluid={images[0].fluid} />
										) : null}
									</div>
									<div className="section-copy-wrapper">
										{copy && (
											<div className="section-copy gutters">
												<Markdown className={`md max32`} field={copy} />
											</div>
										)}
										{customForm && <SectionForm {...customForm} location={location} />}
									</div>
								</div>
							)}
						</Revealer>
					</section>
				) : (
					<section className="section gutters debug" key={reference} style={style}>Missing fields</section>
				)
			})}
		</div>
	)
}

export default Sections

export const sectionFragment = graphql`
  fragment Section on ContentfulSection {
    reference
    images {
      id
      # fluid(maxWidth: 800, quality: 90) {
      #   src
      #   aspectRatio
      #   sizes
      #   srcSet
      # }
      fluid(maxWidth: 1600) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    secondaryImages {
      id
      # fluid(maxWidth: 800, quality: 90) {
      #   src
      #   aspectRatio
      #   sizes
      #   srcSet
      # }
      fluid(maxWidth: 1600) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    copy {
      copy
      childMarkdownRemark {
        html
      }
    }
		## NEW
		customForm {
			formId
			successPage {
				slug
			}
			customFieldGroups {
				title
				description {
					childMarkdownRemark {
						html
					}
				}
				customFormFields {
					label
					name
					type
					required
					placeholder
					options
				}
			}
		}
		##
    backgroundColour
    order
    imageSize
  }
`
