import React from "react"
import PropTypes from 'prop-types'
import { graphql, Link } from "gatsby"
// ---------------------------------------------
import Layout from "../components/layout"
import Sections from "../components/sections"
import RichText from "../components/richtext"
import SEO from "../components/seo"
import useSettings from "../components/useSettings"
// ---------------------------------------------

const BasicPage = ({ data, location }) => {

  const {
    title,
    backgroundColour,
    body,
    sections
  } = data.contentfulPage

  const { legal } = useSettings()

  return (
    <Layout footerColour="#FFFFFF">
      <SEO title={title} />
      <div className="has-links" style={backgroundColour && {
        backgroundColor: backgroundColour
      }}>
        {body ? (
          <article className="page">
            <aside>
              <h3>{title}</h3>
              <div className="outerx2">
                {legal.map((node) => {
                  return (
                    <div key={`${node.label}-footer`}>
                      <Link to={`/${node.link.slug}`}>
                        {node.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </aside>
						
						<div>
							<RichText content={body} debug={title} />
						</div>
          </article>
        ) : sections ? (
          <Sections sections={sections} location={location} />
        ) : null}
      </div>
    </Layout>
  )
}

BasicPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default BasicPage

export const pageQuery = graphql`
  query BasicPageQuery($slug: String!){
    contentfulPage(slug: {eq: $slug}) {
      title
      slug
      backgroundColour
      body {
        raw
				# references {
				# 	... on ContentfulAsset {
				# 		...RichTextEmbeddedAsset
				# 	}
				# }
      }
      sections {
        ...Section
      }
    }
  }
`
