import React, { useState } from "react"
import Img from 'gatsby-image'
import Swiper from 'react-id-swiper'
// ---------------------------------------------
// Components
// ---------------------------------------------
import '../css/components/swiper.scss'


const Gallery = ({ images, className, pictureClassName }) => {

  const [swiper, updateSwiper] = useState(null)
  // const [activeIndex, setActiveIndex] = useState(0)
  // const [currentSlide, setCurrentSlide] = useState(images[activeIndex])

  const handleNext = e => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  }

  const handlePrev = e => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  }

  // useEffect(() => {
  //   setCurrentSlide(images[activeIndex])
  // }, [activeIndex])

  const options = {
    loop: false,
    pagination: images.length > 1 ? {
      el: '.gallery-dots',
      type: 'bullets',
      clickable: false
    } : false
  }

  return (
    <div className={`prel ${className || ''}`}>
      <Swiper {...options} getSwiper={updateSwiper}>
        {images.map((node, i) => (
          <div className="swiper-slide" key={`slide-${i}`}>
            <Img className={pictureClassName} fluid={node.fluid} loading="eager" />
          </div>
        ))}
      </Swiper>
			
			{images.length > 1 && (
				<div
					className="gallery-click pabs"
					onClick={handlePrev}
					style={{
						width: '50%',
						height: '100%',
						cursor: 'w-resize',
						zIndex: 2
					}}
				/>
			)}
      
			{images.length > 1 && (
				<div
					className="gallery-click pabs r0"
					onClick={handleNext}
					style={{
						width: '50%',
						height: '100%',
						cursor: 'e-resize',
						zIndex: 2
					}}
				/>
			)}
      
    </div>
  )
}

export default Gallery
